<template>
  <div class="manuscript2">
    <div class="header">
      <div class="nav_title">
        <p> </p>
        <!-- <p>捐赠作品一览 </p> -->
      </div>
      <div class="pagination_group">
        <div class="pagination"></div>
        <div class="btn_group">
          <img class="btn_left" src="./image/left.png" alt=""/>
          <img class="btn_right" src="./image/right.png" alt=""/>
        </div>
      </div>
    </div>
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in imgList"
          :key="index"
          :class="`delay_${index}`"
          class="swiper-slide wow fadeInUp fadeIn"
        >
          <div class="swiper_slide_img" @click="showImgViewer([require(`./image/picture/${item.url}`)])">
            <img class="swiper_slide_img_pr" :src="require(`./image/picture/${item.url}`)" alt=""/>
            <img class="serach_img" src="./image/serach.png" alt=""/>
          </div>
          <!-- <p>捐赠时间：{{ item.age ? `${item.age}年` : '暂无' }}</p>
          <p>捐赠作品：《{{ item.name }}》</p> -->
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
      <!-- 如果需要导航按钮 -->
      <!-- <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div> -->
    </div>
    <el-image-viewer
      class="img_viewer"
      v-if="imgViewerVisible"
      :on-close="closeImgViewer"
      :url-list="imgViewerList"/>
  </div>
</template>

<script>
// import Swiper from 'swiper';
import { slideImgList } from './data/slide'

export default {
  props: {
    imgList: Array
  },
  data () {
    return {
      slideImgList,
      imgViewerVisible: false,
      imgViewerList: []
    }
  },
  components: { 'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer') },
  mounted () {
    // eslint-disable-next-line no-new
    new Swiper('.swiper-container', {
      // direction: 'vertical', 垂直切换选项
      loop: true, // 开启循环模式选项
      // slidesPerView: 2.3,
      slidesPerView: 'auto',
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
        clickable: true // 点分页时可以切换
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: '.btn_right',
        prevEl: '.btn_left'
      }
    })
  },
  methods: {
    showImgViewer (url) {
      this.imgViewerList = url
      this.imgViewerVisible = true
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', m, false) // 禁止页面滑动
    },
    closeImgViewer () {
      this.imgViewerVisible = false
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'auto'
      document.removeEventListener('touchmove', m, true)
    }
  }
}
</script>

<style lang="less" scoped>
.manuscript2 {
  // width: calc(100vw - 20px);
  height: 100vh;
  background: #fff;

  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #a8a8a8;
  }

  .swiper-container-horizontal > .swiper-pagination-progressbar {
    position: absolute;
    width: calc(100vw * 0.67);
    top: -52px;
    height: 2px;
    left: 13%;
  }

  .swiper-container {
    width: 100%;
    height: 77vh;
    overflow: inherit;
    position: relative;
  }

  .header {
    width: 74%;
    // height: 23vh;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 30px 0;

    .nav_title {
      // padding-left: 140px;
      span {
        font-size: 24px;
        font-weight: 600;
        color: #333;
      }

      p {
        font-size: 36px;
        font-weight: 600;
        color: #333;
      }
    }

    .pagination_group {
      display: flex;

      .pagination {
        width: calc(100vw * 0.67);
      }

      .btn_group {
        display: flex;

        img {
          width: 60px;
          height: 60px;
          display: block;
          margin-left: 30px;
          transition: all 0.3s;

          &:hover {
            cursor: pointer;
            opacity: 0.55;
          }

          &:active {
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }

  .swiper-slide {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 45px;

    .swiper_slide_img {
      position: relative;
      display: flex;
      align-items: center;

      &:hover {
        .serach_img {
          display: block;
          cursor: pointer;
        }
      }
    }

    > p {
      line-height: 40px;
      font-size: 21px;
      font-weight: 500;
      color: #ffffff;

    }

    .serach_img {
      position: absolute;
      width: 80px;
      height: 80px;
      left: 50%;
      top: 50%;
      margin-left: -40px;
      margin-top: -40px;
      display: none;
    }
  }

  .swiper-slide .swiper_slide_img_pr {
    display: block;
    width: 400px;
    max-height: 600px;
    object-fit: cover;
    position: relative;
  }
}
</style>
