// eslint-disable-next-line import/prefer-default-export
export const slideImgList = [
  {
    url: require('../image/sw/swiper01.png')
  },
  {
    url: require('../image/sw/swiper02.png')
  },
  {
    url: require('../image/sw/swiper03.png')
  },
  {
    url: require('../image/sw/swiper04.png')
  }, {
    url: require('../image/sw/swiper01.png')
  },
  {
    url: require('../image/sw/swiper02.png')
  },
  {
    url: require('../image/sw/swiper03.png')
  },
  {
    url: require('../image/sw/swiper04.png')
  }
]
