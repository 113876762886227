<template>
        <div class="m-cultural">
          <div @click.stop="changeShowMenu" :class="navTag ? 'navTag':null" class="bg_div_right">
            <span>导航</span>
            <img v-if="!navTag" class="open" src="../views/components/image/nav/down.png" alt="" srcset="" />
            <img v-else class="close" src="../views/components/image/nav/close.png" alt="" srcset="" />
        </div>
        <div class="hander_box">
          <div data-wow-duration="1s" class="hander_l wow fadeInUp" @click.stop="changeShowMenu">
          </div>
          <p data-wow-duration="1.5s" class="hander_r wow fadeInUp">花魂鸟魄</p>
        </div>
        <MenuTemp ref="refMenuTemp" v-model="navTag" :type="2"></MenuTemp>

        <div class="m-cultural-banner">
            <img src="./components/image/cultural/banner.png" alt="">
            <span class="m-detail-title">文创天地</span>
        </div>
        <div class="m-cultural-container1">
            <div class="m-cultural-container-content1">
                <div class="m-list" v-for="(item,index) in data1List" :key="index">
                    <img class="m-list-img" :src="item.img" alt="" @click="handleLink(item.url)">
                    <p class="m-title">{{item.title}}</p><br>
                    <!-- <div>
                        <span></span>
                        <span>{{item.tip}}</span>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="m-cultural-container2">
             <!-- <div class="m-border">
                <div class="m-border-left"></div>
                <div class="m-border-right"></div>
            </div> -->
            <div class="m-cultural-container2-content2">
                <div v-for="(item,index) in data2List" :key="index">
                    <div class="m-content2-list wow fadeInUp" v-if="index % 2 !== 0">
                        <div class="m-content2-list-even">
                          <div class="m-odd-right">
                              <img class="m-title-img" :src="item.title" alt="" @click="handleLink(item.url)"><br>
                              <span class="m-list-content">{{item.content}}</span>
                          </div>
                          <div class="m-odd-left">
                              <div :class="`m-odd-img${index}-${1+eleIndex}`" v-for="(ele,eleIndex) in item.swiperList" :key="eleIndex">
                                  <img class="m-swiper-img" :src="ele.img" alt="">
                              </div>
                              <div class="m-arrow">
                                  <div class="m-button-left" @click.stop="handleLeft(index)"></div>
                                  <div class="m-box111" @click.stop="handleRight(index)"></div>
                              </div>
                          </div>
                        </div>
                        <div class="m-purchase-button">
                          <div class="m-button" @click="handleLink(item.url)">
                              立即购买
                          </div>
                        </div>
                    </div>
                    <div class="m-content2-list wow fadeInUp" v-else>
                      <div class="m-content2-list-odd">
                        <div class="m-odd-left">
                             <div :class="`m-odd-img${index}-${1+eleIndex}`" v-for="(ele,eleIndex) in item.swiperList" :key="eleIndex">
                                <img class="m-swiper-img" :src="ele.img" alt="" >
                            </div>
                            <div class="m-arrow">
                                <div class="m-button-left" @click.stop="handleLeft(index)"></div>
                                <div class="m-box111" @click.stop="handleRight(index)"></div>
                            </div>
                        </div>
                        <div class="m-odd-right">
                            <img class="m-title-img" :src="item.title" alt="" @click="handleLink(item.url)"><br>
                            <span class="m-list-content">{{item.content}}</span>
                        </div>
                      </div>
                       <div class="m-purchase-button-odd">
                          <div></div>
                          <div class="m-button" @click="handleLink(item.url)">
                              立即购买
                          </div>
                        </div>
                    </div>
                </div>
                <!--  -->

            </div>
        </div>
    </div>
</template>
<script>
// import h from ""
import WOW from 'wowjs'
import MenuTemp from '@/views/components/MenuTemp'
export default {
  components: { MenuTemp },
  data () {
    return {
      navTag: false,
      data1List: [
        {
          img: require('../assets/CulturalCreationDetail/pic1.png'),
          title: '“藕花深处”马克杯',
          tip: '"Deep Lotus Flower" Mug',
          url: 'https://detail.tmall.com/item.htm?spm=a212k0.12153887.0.0.4d7c687dCyfmKN&id=675950483713'
        },
        {
          img: require('../assets/CulturalCreationDetail/pic2.png'),
          title: '“桃来礼搭”纪念文具礼盒',
          tip: 'stationery gift box',
          url: 'https://detail.tmall.com/item.htm?spm=a212k0.12153887.0.0.2001687d9gLWV8&id=676609506032'
        },
        {
          img: require('../assets/CulturalCreationDetail/pic3.png'),
          title: '“一品红”竹骨扇',
          tip: '"Poinsettia" Bamboo Bone Fan',
          url: 'https://detail.tmall.com/item.htm?spm=a212k0.12153887.0.0.2001687d9gLWV8&id=675429828785'
        },
        {
          img: require('../assets/CulturalCreationDetail/pic4.png'),
          title: '邹传安画作万用清洁布',
          tip: 'Universal cleaning cloth',
          url: 'https://detail.tmall.com/item.htm?spm=a212k0.12153887.0.0.2001687d9gLWV8&id=676136553642'
        }
      ],
      data2List: [
        {
          url: 'https://detail.tmall.com/item.htm?spm=a212k0.12153887.0.0.4d7c687dCyfmKN&id=675950483713',
          swiperList: [
            { img: require('../assets/CulturalCreationDetail/swiper1.png') },
            { img: require('../assets/CulturalCreationDetail/swiper2.png') },
            { img: require('../assets/CulturalCreationDetail/swiper3.png') }
          ],
          title: require('../assets/CulturalCreationDetail/01.png'),
          content: '本产品以邹传安先生画作“藕花深处”为设计元素。此画绘满荷叶荷花，有“接天莲叶无穷碧”之意境，藕华深处，鸟儿飞逐，又得易安《如梦令·常记溪亭日暮》词意。邹传安先生将诗意落入画里，我们将画作复刻于瓷上。精选优质骨瓷，将恬静娴雅融于杯身设计，高温烧制，透亮轻盈，坚固耐用。瓷杯配以不锈钢勺，搅拌方便，包装礼盒简约大气，颜值实用两相宜，以此为赠礼佳品，感受诗情画意，传递不俗品味。'
        },
        {
          url: 'https://detail.tmall.com/item.htm?spm=a212k0.12153887.0.0.2001687d9gLWV8&id=676609506032',
          swiperList: [
            { img: require('../assets/CulturalCreationDetail/swiper4.png') },
            { img: require('../assets/CulturalCreationDetail/swiper5.png') },
            { img: require('../assets/CulturalCreationDetail/swiper6.png') }
          ],
          title: require('../assets/CulturalCreationDetail/02.png'),
          content: '纪念礼盒由笔记本与书签组成。其中笔记本以邹传安先生画作《来客何为》为封面设计元素。画中桃实饱满，桃叶鲜活。雀鸟伸颈歪头张望，似对小虫充满好奇，是欲捕食猎物还是问候来客？笔记本装订讲究，印刷精美，纸张书写流畅，本产品将邹老先生画作与笔记本完美结合，在日常的书写之中带你感受画境之美。礼盒中书签以邹传安先生的四幅经典作品（《四喜》《南天耀羽》《流丹耀羽》《追》）为设计元素。这四幅画作展现了邹传安先生精湛的画工和不凡的意趣。古雅厚重，寓意深远，兼具艺术性和装饰性。其中留白部分，可供玩赏者自行发挥题写文字，与艺术大师神会交流。'
        },
        {
          url: 'https://detail.tmall.com/item.htm?spm=a212k0.12153887.0.0.2001687d9gLWV8&id=675429828785',
          swiperList: [
            { img: require('../assets/CulturalCreationDetail/swiper7.png') },
            { img: require('../assets/CulturalCreationDetail/swiper8.png') },
            { img: require('../assets/CulturalCreationDetail/swiper9.png') }
          ],
          title: require('../assets/CulturalCreationDetail/03.png'),
          content: '扇面以邹传安先生画作《一品红》为元素。画中荔枝鲜红欲坠，令人望之生津。雀鸟黄蜂，各自闲忙，画面生动，意趣盎然。折扇背面为邹传安先生书法作品“且留余地”。事、物、文、画、人，皆勿太满太过，留有余地，方可徐徐品味，以望将来。这是邹传安先生的艺术感悟，更是人生经验。'
        },
        {
          url: 'https://detail.tmall.com/item.htm?spm=a212k0.12153887.0.0.2001687d9gLWV8&id=676136553642',
          swiperList: [
            { img: require('../assets/CulturalCreationDetail/swiper10.png') },
            { img: require('../assets/CulturalCreationDetail/swiper11.png') },
            { img: require('../assets/CulturalCreationDetail/swiper12.png') }
          ],
          title: require('../assets/CulturalCreationDetail/04.png'),
          content: '万用清洁布以邹传安先生工笔花卉作品《长袖多情》与《花月无言》为设计元素。其中《长袖多情》绘虞美人，并题诗述虞姬事迹；《花月无言》绘芍药与明月。花朵怒放，设色饱满，鲜明而沉静。将画作元素展现在清洁布之上，一笔一画，勾勒经典。每块清洁布都是独立包装，干净美观，随时随地，清洁小物。'
        }
      ]
    }
  },
  mounted () {
    new WOW.WOW().init()
    // 定时器
    setTimeout(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }, 0)
    // 回调函数
  },
  methods: {
    changeShowMenu () {
      this.$refs.refMenuTemp.changeShowMenu()
    },
    handleLink (url) {
      console.log(url, 'hahah')
      window.location.href = url
    },
    handleLeft (index) {
      const that = this
      this.$nextTick(() => {
        switch (index) {
          case 0:
            that.leftCommonMethods(0)
            break
          case 1:
            that.leftCommonMethods(1)
            break
          case 2:
            that.leftCommonMethods(2)
            break
          case 3:
            that.leftCommonMethods(3)
        }
      })
    },
    leftCommonMethods (index) {
      const left = document.querySelector(`.m-odd-img${index}-1`)
      const center = document.querySelector(`.m-odd-img${index}-2`)
      const right = document.querySelector(`.m-odd-img${index}-3`)
      left.className = `m-odd-img${index}-3`
      center.className = `m-odd-img${index}-1`
      right.className = `m-odd-img${index}-2`
    },
    rightCommonMethods (index) {
      const left = document.querySelector(`.m-odd-img${index}-1`)
      const center = document.querySelector(`.m-odd-img${index}-2`)
      const right = document.querySelector(`.m-odd-img${index}-3`)
      left.className = `m-odd-img${index}-2`
      center.className = `m-odd-img${index}-3`
      right.className = `m-odd-img${index}-1`
    },
    handleRight (index) {
      const that = this
      this.$nextTick(() => {
        switch (index) {
          case 0:
            that.rightCommonMethods(0)
            break
          case 1:
            that.rightCommonMethods(1)
            break
          case 2:
            that.rightCommonMethods(2)
            break
          case 3:
            that.rightCommonMethods(3)
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.m-cultural{
    width: 100vw;
    &-banner{
        width: 100%;
        height: 500px;
        position: relative;
        img{
            width: 100%;
            height: 100%;
        }
      .m-detail-title{
        position: absolute;
        height: 68px;
        font-size: 48px;
        font-weight: 600;
        color: #FFF;
        top: 130px;
        left: 63px;
      }
    }
    &-container1{
        width: 100%;
        height: 363px;
        display: flex;
        justify-content: center;
        background: #F3F3F3;
    }
    &-container2{
        width: 100%;
        min-height: 363px;
        display: flex;
        align-items: center;
        flex-direction: column;
        background: #FFF;
    }
}
.m-cultural-container-content1{
    width: 1200px;
    height: 470px;
    display: flex;
    justify-content: space-between;
    .m-list{
        width: 270px;
        height: 366px;
        background-image: url('../assets/CulturalCreationDetail/background2.png');
        background-size: 100% 100%;
        padding: 40px 39px;
        box-sizing: border-box;
        margin-top: 54px;
        box-shadow: 6px 6px 30px #d6d1d1;

        &-img{
          cursor: pointer;
            width: 192px;
            height: 192px;
            margin-bottom: 59px;
        }
        .m-title{
            width: 225px;
            font-size: 18px;
            font-weight: 500;
            color: #494949;
            margin-top: -10px;
            text-align: left;
        }
        div{
            margin-top: -10px;
            width: 225px;
            height: 20px;
            line-height: 20px;
            display: flex;
            align-items: center;
            // justify-content: left;
            text-align: left;
            span:nth-child(1){
            display: inline-block;
            width: 13px;
            height: 13px;
            background: #66BBD2;
            margin-right: 10px;
            }
            span:nth-child(2){
                display: inline-block;
                font-size: 14px;
                font-family: Source Han Serif CN;
                font-weight: 400;
                color: #999999;
            }
        }
    }
    }

    // container2
   .m-cultural-container2{
    width: 100%;
    padding-top: 107px;
    min-height: 500px;
    padding-bottom: 176px;
    .m-border{
        width: 1200px;
        display: flex;
        height: 4px;
       &-left{
        width: 447px;
        height: 4px;
        background: #EAC16B;
        border-radius: 1px;
       }
       &-right{
        width: 753px;
        height: 4px;
        background: #BABABA;
        border-radius: 1px;
       }
    }
    &-content2{
        width: 1200px;
        min-height: 300px;
        // background: pink;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -35px;
    }
}

// 奇数列表
.m-content2-list{
    width: 1160px;
    min-height: 418px;
    margin-top: 111px;
    // background: #66BBD2;
    box-shadow: 4px 4px 15px #f4efef;
     border-radius: 10px;
     padding-bottom: 43px;
     padding-right: 50px;
     box-sizing: border-box;
}
.m-content2-list-odd{
    display: flex;
    justify-content: space-between;
    .m-odd-left{
       width: 418px;
       height: 100%;
       background: salmon;
       position: relative;
       .m-odd-img0-1,.m-odd-img1-1,.m-odd-img2-1,.m-odd-img3-1{
        position: absolute;
        left: -40px;
        top: -30px;
        width: 368px;
        height: 368px;
        // background: #BABABA;
        z-index: 30;

        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s;
       }
       .m-odd-img0-2,.m-odd-img1-2,.m-odd-img2-2,.m-odd-img3-2{
        position: absolute;
        left: 20px;
        top: -10px;
        width: 328px;
        height: 328px;
        // background: yellow;
        z-index: 20;

        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s;
       }
       .m-odd-img0-3,.m-odd-img1-3,.m-odd-img2-3,.m-odd-img3-3{
        position: absolute;
        left: 80px;
        top: 10px;
        width: 288px;
        height: 288px;
        // background:green;
        z-index: 10;

        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s;
       }
       .m-arrow{
        position: absolute;
        left: -40px;
        top: -30px;
        width: 368px;
        height: 368px;
        // background: #66BBD2;
        z-index: 40;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        box-sizing: border-box;
       .m-button-left{
        cursor: pointer;
        width: 48px;
        height: 48px;
        border-radius: 48px;
        z-index: 40;
        background-image: url('../assets/CulturalCreationDetail/left1.png');
        background-size: 100% 100%;
       }
       .m-box111{
        cursor: pointer;
        width: 48px;
        height: 48px;
        border-radius: 48px;
        z-index: 40;
        background-image: url('../assets/CulturalCreationDetail/right.png');
        background-size: 100% 100%;
       }
       .m-button-left:hover{
         background-image: url('../assets/CulturalCreationDetail/activeleft.png');
       }
       .m-box111:hover{
         background-image: url('../assets/CulturalCreationDetail/activeright.png');
       }
       }
    }
    .m-odd-right{
       width: 782px;
       height: 100%;
    //    background: darkkhaki;
       padding-left: 51px;
       box-sizing: border-box;
       .m-title-img{
        margin-top: 60px;
        cursor: pointer;
       }
       .m-list-content{
        display: inline-block;
        margin-top: 40px;
        font-size: 16px;
        font-family: Source Han Serif CN;
        font-weight: 400;
        color: #666666;
        line-height: 36px;
        // margin-left: 38px;
       }
    }
}

// 偶数列表
.m-content2-list{
  width: 1160px;
    min-height: 418px;
    margin-top: 111px;
    // background: #66BBD2;

     box-shadow: 4px 4px 15px #f4efef;

     border-radius: 10px;
     padding-bottom: 43px;
     box-sizing: border-box;
     padding-left: 50px;
}
.m-purchase-button-odd{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
  .m-button{
    cursor: pointer;
    width: 125px;
    height: 39px;
    background: #FFFFFF;
    border: 1px solid #0D5256;
    border-radius: 19px;
    text-align: center;
    line-height: 39px;
    margin-top: 16px;
    font-size: 20px;
    font-family: Source Han Serif CN;
    font-weight: 500;
    color: #0D5256;
  }
.m-purchase-button{
  width: 100%;
}
.m-content2-list-even{
     display: flex;
    justify-content: space-between;
    .m-odd-left{
       width: 418px;
       height: 100%;
    //    background: salmon;
       position: relative;
       .m-odd-img0-1,.m-odd-img1-1,.m-odd-img2-1,.m-odd-img3-1{
        position: absolute;
        right: -40px;
        top: -30px;
        width: 368px;
        height: 368px;
        z-index: 30;
         -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s;
       }
       .m-odd-img0-2,.m-odd-img1-2,.m-odd-img2-2,.m-odd-img3-2{
        position: absolute;
        right: 20px;
        top: -10px;
        width: 328px;
        height: 328px;
        z-index: 20;
         -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s;
       }
       .m-odd-img0-3,.m-odd-img1-3,.m-odd-img2-3,.m-odd-img3-3{
        position: absolute;
        right: 80px;
        top: 10px;
        width: 288px;
        height: 288px;
        // background:green;
        z-index: 10;
         -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s;
       }

    }
    .m-odd-right{
       width: 782px;
       height: 100%;
    //    background: darkkhaki;
    //    padding-left: 51px;
       box-sizing: border-box;
       .m-title-img{
        margin-top: 60px;
       }
       .m-list-content{
        display: inline-block;
        margin-top: 40px;
        font-size: 16px;
        font-family: Source Han Serif CN;
        font-weight: 400;
        color: #666666;
        line-height: 36px;
        margin-right: 51px;
       }
    }
    .m-arrow{
        position: absolute;
       right: -40px;
        top: -30px;
        width: 368px;
        height: 368px;
        // background: #66BBD2;
        z-index: 40;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        box-sizing: border-box;
       .m-button-left{
        cursor: pointer;
        width: 48px;
        height: 48px;
        border-radius: 48px;
        z-index: 40;
        background-image: url('../assets/CulturalCreationDetail/left1.png');
        background-size: 100% 100%;
       }
       .m-box111{
        cursor: pointer;
        width: 48px;
        height: 48px;
        border-radius: 48px;
        background-image: url('../assets/CulturalCreationDetail/right.png');
        background-size: 100% 100%;
       }
       .m-button-left:hover{
         background-image: url('../assets/CulturalCreationDetail/activeleft.png');
       }
       .m-box111:hover{
         background-image: url('../assets/CulturalCreationDetail/activeright.png');
       }
    }
}

.m-swiper-img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
</style>
