<template>
  <div class="my_list">
     <div @click.stop="changeShowMenu" :class="navTag ? 'navTag':null" class="bg_div_right">
        <span>导航</span>
        <img v-if="!navTag" class="open" src="../views/components/image/nav/down.png" alt="" srcset="" />
        <img v-else class="close" src="../views/components/image/nav/close.png" alt="" srcset="" />
    </div>
    <div class="hander_box">
      <div class="hander_l" @click.stop="changeShowMenu">
        <!-- <img src="../assets/mylist/list.png" alt="" srcset=""/>
        <span>导航</span> -->
      </div>
      <p @click="goBack" class="hander_r">花魂鸟魄</p>
    </div>
    <MenuTemp ref="refMenuTemp"  v-model="navTag" :type="2"></MenuTemp>
    <div class="nav">
      <div class="public_max_left_title">
        <div class="public_max_left_box"><span>{{ infoData.name }}</span></div>
        <div class="public_max_left_h1">
          <!-- <span>{{ infoData.label }}</span> -->
          <p>{{ infoData.info }}</p>
        </div>
      </div>
      <div class="tab_list">
        <el-tabs v-model="activeName" @tab-click="getList" :stretch="true">
          <!-- <el-tab-pane label="全部" name=""></el-tab-pane> -->
          <el-tab-pane v-for="item in tabList" :label="item.name" :name="item.id.toString()"
                       :key="item.id"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div id="certify">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in imgList" :key="index" :data-index="index">
            <!--            <div class="swiper-slide-box" :data-list="item.multi_graph" @click="showImgViewer(item.multi_graph)">-->
            <div class="swiper-slide-box" :data-index="index">
              <img class="swiper-slide-box-img" :src="`${item.picture.replace('/images/','/thumb/images/')}`" :data-index="index"/>
              <img class="swiper-slide-box-icon" src="../assets/list/icon_preview.png" :data-index="index"/>
              <div class="swiper-slide-info">
                <div class="title">{{ item.name }}</div>
                <div class="text">{{ item.annotations }}</div>
              </div>
            </div>
            <p>{{ index + 1 }}/{{ imgList.length }}</p>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
    <el-image-viewer
      class="img_viewer"
      v-if="imgViewerVisible"
      :on-close="closeImgViewer"
      :url-list="imgViewerList"/>

    <div class="footer">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ infoData.name }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ infoData.info }}</el-breadcrumb-item>
        <el-breadcrumb-item>作品</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 图片详情 -->
     <MaxOpen ref="refMaxOpen"/>
  </div>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery'
import { request } from '@/utils/request'
import MenuTemp from '@/views/components/MenuTemp'
import MaxOpen from '@/components/maxOpen/MaxOpen.vue'
export default {
  name: 'Home',
  components: {
    MaxOpen,
    MenuTemp,
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  data () {
    return {
      navTag: false,
      imgList: [],
      tabList: [],
      imgViewerVisible: false,
      imgViewerList: [],
      activeName: '',
      typeId: null,
      infoData: {
        name: '花踪叶影',
        info: '雕虫筑鸟老瓷工',
        label: '浅入',
        rightLabel: '花魂鸟魄'
      }
    }
  },
  created () {
    if (this.$route.query) {
      const {
        name,
        info,
        label
      } = this.$route.query
      this.typeId = this.$route.query.id
      this.infoData = {
        name,
        info,
        label
      }
      this.getAllType()
    }
  },
  mounted () {
    $('img').attr({ oncontextmenu: 'return false;', ondragstart: 'return false;' })
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    changeShowMenu () {
      this.$refs.refMenuTemp.changeShowMenu()
    },
    showImgViewer (url) {
      this.imgViewerList = url
      this.imgViewerVisible = true
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', m, false) // 禁止页面滑动
    },
    closeImgViewer () {
      this.imgViewerVisible = false
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'auto'
      document.removeEventListener('touchmove', m, true)
    },
    getList () {
      request('/api/artworks/content', 'get', {
        classification_id: this.activeName,
        per_page: 20000
      }).then(res => {
        this.imgList = res.data
        this.$nextTick(() => {
          this.setSwiper()
        })
      })
    },
    getAllType () {
      request('/api/classification/content', 'get', {
        sub_title_id: this.typeId,
        per_page: 20000
      }).then(res => {
        this.tabList = res.data
        if (res.data.length > 0) {
          this.activeName = res.data[0].id.toString()
          this.getList()
        }
      })
    },
    setSwiper () {
      // eslint-disable-next-line no-undef
      const _this = this
      const certifySwiper = new Swiper('#certify .swiper-container', {
        watchSlidesProgress: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        initialSlide: 3, // 默认第二个
        // loop: true,
        // autoplay: 7000,
        speed: 300,
        loopedSlides: 5,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // pagination: {
        //   el: '.swiper-pagination'
        //   // clickable :true,
        // },
        on: {
          click: function (e) {
            const index = e.target.getAttribute('data-index') // 获取自定义属性
            // console.log(e, index)
            _this.$refs.refMaxOpen.open(_this.imgList[Number(index)])
            // _this.showImgViewer(_this.imgList[Number(index)].multi_graph)
          },
          progress: function (progress) {
            for (let i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i)
              var slideProgress = this.slides[i].progress
              let modify = 1
              if (Math.abs(slideProgress) > 1) {
                modify = (Math.abs(slideProgress) - 1) * 0.4 + 1
              }
              const translate = slideProgress * modify * 50 + 'px'
              const scale = 1 - Math.abs(slideProgress) / 10
              const zIndex = 999 - Math.abs(Math.round(10 * slideProgress))
              slide.transform(
                'translateX(' + translate + ') scale(' + scale + ')'
              )
              slide.css('zIndex', zIndex)
              slide.css('opacity', 1)
              if (Math.abs(slideProgress) > 3) {
                slide.css('opacity', 0)
              }
            }
          },
          setTransition: function (transition) {
            for (var i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i)
              slide.transition(transition)
            }
          }
        }
      })
      console.debug(certifySwiper)
    }
  }
}
</script>
<style lang="less">
.el-image-viewer__wrapper {
  z-index: 99999999 !important;
}

.my_list {
  .el-tabs__item {
    font-size: 24px;
    font-weight: 400;
    color: #5c5c5c;
    position: relative;
    padding-bottom: 5px;
    cursor: pointer;
    height: 45px;
    max-width: 250px;
  }

  .el-tabs__item.is-active {
    font-weight: 600;
    color: #5C5C5C;
  }

  .el-tabs__nav-next, .el-tabs__nav-prev {
    font-size: 18px;
  }

  .el-tabs__active-bar {
    background: #707070;
    height: 3px;
  }
  .el-tabs__nav-wrap {
    display: flex;
    justify-content: flex-end;
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
}
</style>

<style lang="less" scoped>
.my_list {
  width: 100vw;
  height: 100vh;
  background: #F4F3F1;

  .footer {
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    display: flex;
    align-items: center;

    .breadcrumb {
      color: #333;
      padding-left: 58px;
    }
  }

  .nav {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-top: 5%;

    .tab_list {
      // padding: 57px 150px 70px 150px;
      position: absolute;
      right: 50px;
      max-width: 800px;
      width: 500px;

      ul {
        display: flex;

        li {
          font-size: 24px;
          font-weight: 400;
          color: #5c5c5c;
          margin-right: 60px;
          position: relative;
          padding-bottom: 5px;
          cursor: pointer;
        }

        .active {
          font-weight: 600;

          &::before {
            content: "";
            width: 100%;
            height: 4px;
            position: absolute;
            background: #5c5c5c;
            bottom: 0;
            border-radius: 2px;
          }
        }
      }
    }
  }

  .public_max_left_title {
    display: flex;
    height: 140px;
    // padding-top: 6.5%;
    .public_max_left_box {
      width: 140px;
      height: 140px;
      background: #eac06b;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: relative;

      span {
        font-size: 48px;
        font-weight: 600;
        color: #5c5c5c;
        position: absolute;
        width: 192px;
        top: 60px;
        left: 48px;
      }
    }

    .public_max_left_h1 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100px;
      // margin-left: 10%;
      overflow: hidden;
      position: relative;
      padding-left: 180px;
      margin-top: 30px;

      &::before {
        content: "";
        width: 21px;
        height: 90px;
        background: url("~@/assets/mylist/line.png") no-repeat;
        background-size: 100%;
        position: absolute;
        left: 130px;
        top: 16px;
      }

      // align-items: center;
      span {
        font-size: 42px;
        font-weight: 600;
        color: #5c5c5c;
      }

      p {
        font-size: 34px;
        font-weight: 400;
        line-height: 90px;
        padding-top: 14px;
        color: #5c5c5c;
        position: relative;
      }
    }
  }

  .hander_box {
    display: flex;
    justify-content: space-between;
    position: fixed;
    padding-top: 22px;
    padding-bottom: 22px;
    width: 100%;
    left: 0;
    top: 0;

    .hander_r {
      font-size: 36px;
      color: #5c5c5c;
      padding-right: 68px;
      cursor: pointer;
    }

    .hander_l {
      padding-left: 68px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 54px;
        height: 15px;
      }

      span {
        font-size: 21px;
        color: #5c5c5c;
        padding-left: 20px;
        display: block;
      }
    }
  }

  #certify {
    position: relative;
    width: 90vw;
    margin: 7% auto 0;
  }

  #certify .swiper-container {
    padding-bottom: 60px;
  }

  #certify .swiper-slide {
    width: 300px;
    height: 408px;
    background: #fff;
    box-shadow: 0 8px 30px #ddd;
    border: 5px solid #fff;

    .swiper-slide-box {
      position: relative;
      cursor: pointer;

      .swiper-slide-box-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
      }

      .swiper-slide-info {
        height: 0;
        overflow: hidden;
        transition: all 0.5s;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.43);
        color: #fff;
        box-sizing: border-box;

        .title {
          font-size: 16px;
          margin-bottom: 5px;
          /*1. 先强制一行内显示文本*/
          white-space: nowrap;
          /*2. 超出的部分隐藏*/
          overflow: hidden;
          /*3. 文字用省略号替代超出的部分*/
          text-overflow: ellipsis;

        }

        .text {
          overflow: hidden;

          text-overflow: ellipsis;

          display: -webkit-box;

          -webkit-box-orient: vertical;

          -webkit-line-clamp: 2;
          font-size: 12px;

        }
      }

      &:hover {
        .swiper-slide-info {
          height:90px;
          padding: 14px 17px 0;
        }

        .swiper-slide-box-icon {
          display: block;
        }
      }

    }
  }

  #certify .swiper-slide .swiper-slide-box-img {
    width: 300px;
    height: 408px;
    // background: red;
    display: block;
    object-fit: cover;
  }

  #certify .swiper-slide p {
    line-height: 36px;
    padding-top: 0;
    color: #636363;
    font-size: 1.1em;
    margin: 0;
  }

  #certify .swiper-pagination {
    width: 100%;
    bottom: 20px;
  }

  #certify .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
    border: 3px solid #fff;
    background-color: #d5d5d5;
    width: 10px;
    height: 10px;
    opacity: 1;
  }

  #certify .swiper-pagination-bullets .swiper-pagination-bullet-active {
    border: 3px solid #00aadc;
    background-color: #fff;
  }

  #certify .swiper-button-prev {
    left: -30px;
    width: 58px;
    height: 58px;
    background: url("../assets/list/left.png") no-repeat;
    background-size: 100%;

    &:hover {
      opacity: .65;
    }

    &:active {
      // position: relative;
      // top:1px;
    }
  }

  #certify .swiper-button-next {
    right: -30px;
    width: 58px;
    height: 58px;
    background: url("../assets/list/right.png") no-repeat;
    background-size: 100% 100%;

    &:hover {
      opacity: .65;
    }

    &:active {
      // position: relative;
      // top:1px;
    }
  }
}
</style>
