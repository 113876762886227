<template>
  <div class="li_cheng">
     <div @click.stop="changeShowMenu" :class="navTag ? 'navTag':null" class="bg_div_right">
        <span>导航</span>
        <img v-if="!navTag" class="open" src="../views/components/image/nav/down.png" alt="" srcset="" />
        <img v-else class="close" src="../views/components/image/nav/close.png" alt="" srcset="" />
    </div>
    <div class="hander_box">
      <div data-wow-duration="1s" class="hander_l wow fadeInUp" @click.stop="changeShowMenu">
      </div>
      <!-- <p data-wow-duration="1.5s" class="hander_r wow fadeInUp">花魂鸟魄</p> -->
    </div>
    <MenuTemp ref="refMenuTemp" v-model="navTag" :type="2"></MenuTemp>

    <!-- 内容区域 slideInUp -->
    <div class="content">
      <div class="content_item hong_zhua_gr1">
        <div class="hong_zhua_title wow fadeInUp fadeIn">
          <div class="hong_zhua_title_box"></div>
          <span>艺术大事记</span>
        </div>
      </div>
      <div class="content-container">
        <div class="wrap">
          <div class="box wow fadeInUp">
              <div class="left">
                <div class="left-box1">
                  <h3>1956年 / 丙申 16岁</h3>
                  <h5>报考新化瓷厂被录取。</h5>
                  <img src="../assets/licheng/zouchuanan1.png" alt="">
                  <br>
                  <span>*上世纪六十年代于新华瓷厂学徒的邹传安</span>
                </div>
                <div class="left-box2">
                  <div></div>
                </div>
              </div>
              <div class="center"><span>
              </span></div>
              <div class="right m-right-1956-style">
                <div class="m-right-1956-style1"></div>
                <div class="m-right-1956-style2"></div>
                <div class="m-right-1956-style3"></div>
              </div>
          </div>

          <div class="box wow fadeInUp">
              <div class="left">
              </div>
              <div class="center"><span>
              </span></div>
              <div class="right">
                  <div class="m-dashed"></div>
                  <div class="right-box1">
                  <h3>1959年 / 己亥 19岁</h3>
                  <h5>作品参加新化县文化馆组织的展览。</h5>
                </div>
              </div>
          </div>

          <div class="box wow fadeInUp"  style="height:150px">
              <div class="left"></div>
              <div class="center"><span>
              </span></div>
               <div class="right">
                  <div class="m-dashed"></div>
                  <div class="right-box1">
                  <h3>1962年 / 壬寅 22岁</h3>
                  <h5>作品参加邵阳地区的美术作品展。</h5>
                </div>
              </div>
          </div>

          <div class="box wow fadeInUp">
              <div class="left left-four">
                <div class="left-box1 left-box1-four">
                  <h3>1974年 / 甲寅 34岁 <span>&nbsp;&nbsp;&nbsp;</span></h3>
                  <h5>
                    《松鹰》入选湖南省美术作品展。</h5>
                </div>
                 <div class="left-box2 left-box2-four">
                  <div></div>
                </div>
              </div>
              <div class="center"><span>
              </span></div>
              <div class="right"></div>
          </div>

          <div class="box wow fadeInUp" style="height:136px">
              <div class="left"></div>
              <div class="center"><span>
              </span></div>
              <div class="right">
                  <div class="m-dashed"></div>
                  <div class="right-box1">
                  <h3>1976年 / 丙辰 36岁</h3>
                  <h5>《芙蓉》获湖南省美术作品展优秀奖。</h5>
                </div>
                <div class="right-box2">
                 <img src="../assets/licheng/zouchuanan2.png" alt="">
                 <p>*上世纪七十年代邹传安照</p>
                 <div class="m-green-box"></div>
                </div>
              </div>
          </div>

          <div class="box wow fadeInUp" style="height:136px">
              <div class="left">
                <div class="left-box1">
                  <h3>1982年 / 壬戌 42岁</h3>
                  <h5 class="m-long-h5">应邀在湖南省轻工业专科学校美术系教授工笔花鸟课程。</h5>
                </div>
                <div class="left-box2">
                  <div></div>
                </div>
              </div>
              <div class="center"><span>
              </span></div>
              <div class="right"></div>
          </div>
          <div class="box wow fadeInUp">
              <div class="left m-left-1983-style">
                <div class="m-left-1983-style1"></div>
                <div class="m-left-1983-style2"></div>
                <div class="left-box1">
                  <h3>1983年 / 癸亥 43岁</h3>
                  <h5 class="m-long-h5">3月，《梨花纹鸠》、《清荫》、《芙蓉》三件作品参加“湖南省首届工笔画展”，在中国美术馆展出，并为中国美术馆收藏。</h5>
                  <img class="m-picture1" src="../assets/licheng/picture1.jpg" alt=""><br>
                  <span>*1983年中国美术馆入藏凭证</span>
                  <h5 style="margin-top:43px">6月，晋升“陶瓷美术工程师”。</h5>
                  <img class="m-picture2" src="../assets/licheng/picture2.jpg" alt=""><br>
                  <span class="m-long-h5">*1983年8月27日邹传安获授“陶瓷美术工程师”证书</span>
                </div>
                <div class="left-box2">
                  <div></div>
                </div>
              </div>
              <div class="center"><span>
              </span></div>
              <div class="right"></div>
          </div>
          <div class="box wow fadeInUp" style="height:510px">
              <div class="left"></div>
              <div class="center"><span>
              </span></div>
              <div class="right">
                  <div class="m-dashed"></div>
                  <div class="right-box1">
                  <h3>1984年 / 甲子 44岁</h3>
                  <h5 class="m-long-h5">《花有清香月有阴》入选第六届全国美展。</h5>
                  <h5 style="width:434px">应邀在广州美术学院国画系、湖南师范大学艺术学院、湖南工艺美术大学任工笔花鸟课。</h5>
                  <img src="../assets/licheng/picture3.jpg" alt="" class="m-picture3"><br>
                  <p>*在广州美术学院与陈金章合影</p>
                </div>
              </div>
          </div>
          <div class="box wow fadeInUp">
              <div class="left"></div>
              <div class="center"><span>
              </span></div>
                <div class="right">
                  <div class="m-dashed"></div>
                  <div class="right-box1">
                    <h3>1985年 / 乙丑 45岁</h3>
                    <h5>5月，调入湖南省娄底地区文联。</h5>
                  </div>
              </div>
          </div>
          <div class="box wow fadeInUp">
              <div class="left"></div>
              <div class="center"><span>
              </span></div>
              <div class="right m-right-46-style">
                <div class="m-dashed"></div>
                <div class="right-box1">
                  <h3>1986年 / 丙寅 46岁</h3>
                  <h5>7月，当选湖南省娄底地区美协主席。</h5>
                </div>
                 <div class="m-yellow-right">
                  </div>
              </div>
          </div>
          <div class="box wow fadeInUp">
              <div class="left left-style">
                <div class="left-style1"></div>
                <div class="left-style2"></div>
                <div class="left-box1 left-box1-47">
                  <h3>1987年 / 丁卯 47岁</h3>
                  <h5>9月，加入中国美术家协会。</h5>
                  <img src="../assets/licheng/picture4.jpg" alt="">
                  <br>
                  <span>*中国美协入会公函</span>
                </div>
                <div class="left-box2 left-box2-47">
                  <div></div>
                </div>
              </div>
              <div class="center"><span>
              </span></div>
              <div class="right"></div>
          </div>
          <div class="box wow fadeInUp" style="height:500px">
              <div class="left"></div>
              <div class="center"><span>
              </span></div>
              <div class="right">
                <div class="m-dashed"></div>
                <div class="right-box1">
                  <h3>1990年 / 庚午 50岁</h3>
                  <h5 class="m-long-h5">9月，《工笔花鸟画技法》由湖南美术出版社出版。</h5>
                  <div class="right-box1-list">
                    <div>
                      <img src="../assets/licheng/picture5.png" alt=""><br>
                      <span>*《工笔花鸟画技法》第一版封面图</span>
                    </div>
                    <div>
                      <img src="../assets/licheng/picture6.jpg" alt=""><br>
                      <span>*1990.9.20邹传安照</span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="box wow fadeInUp">
                  <div class="left">
                <div class="left-box1">
                  <h3>1991年 / 辛未 51岁</h3>
                  <h5 class="m-51-h5">9月，由湖南省美术家协会、湖南师范大学艺术学院美术系主办的“邹传安画展”于湖南师范大学艺术学院展出。</h5>
                  <div class="m-tuxiang">
                    <div v-for="(item,index) in tuxiangData" :key="index">
                      <img :src="item.img" alt="">
                      <br>
                      <span>{{item.title}}</span>
                    </div>
                  </div>
                </div>
                <div class="left-box2">
                  <div></div>
                </div>
              </div>
              <div class="center"><span>
              </span></div>
              <div class="right"></div>
          </div>
          <div class="box wow fadeInUp" style="height:450px">
              <div class="left"></div>
              <div class="center"><span>
              </span></div>
              <div class="right">
                  <div class="m-dashed"></div>
                  <div class="right-box1">
                  <h3>1992年 / 壬申 52岁</h3>
                  <h5 class="m-long-h5">5月，香港云峰画苑举办“邹传安工笔花鸟画展”。</h5>
                  <img src="../assets/licheng/picture7.png" alt="" class="m-picture3"><br>
                  <p class="m-long-h5">*1992年香港云峰画苑举办“邹传安工笔花鸟画展”</p>
                  <h5 style="margin-top:24px">6月，出任娄底地区文联副主席。</h5>
                </div>
              </div>
          </div>
          <div class="box wow fadeInUp">
              <div class="left"></div>
              <div class="center"><span>
              </span></div>
              <div class="right m-right-53">
                  <div class="m-dashed"></div>
                  <div class="right-box1">
                  <h3>1993年 / 癸酉 53岁</h3>
                  <h5>《香雪》入选第八届全国美展。</h5>
                  <div class="m-right-53-style">
                    <div class="m-right-53-style1"></div>
                    <div class="m-right-53-style2"></div>
                  </div>
                </div>
              </div>
          </div>
          <div class="box wow fadeInUp">
              <div class="left"></div>
              <div class="center"><span>
              </span></div>
               <div class="right">
                  <div class="m-dashed"></div>
                  <div class="right-box1">
                  <h3>1994年 / 甲戌 54岁</h3>
                  <h5>8月，晋职一级美术师。</h5>
                  <img class="m-picture3" src="../assets/licheng/picture9.jpg" alt=""><br>
                  <p>*1994年8月邹传安受评为“一级美术师”证书</p>
                </div>
              </div>
          </div>
          <div class="box wow fadeInUp" style="height:360px">
              <div class="left m-left-55-style">
                <div class="m-left-55-style1"></div>
                <div class="m-left-55-style2"></div>
                <div class="left-box1">
                  <h3>1995年 / 乙亥 55岁</h3>
                  <h5 class="m-long-h5">任娄底地区文联名誉主席，兼美协主席、画院院长。</h5>
                  <img class="m-picture-55" src="../assets/licheng/picture8.png" alt="">
                  <br>
                  <span>*1995年在娄底地区画院成立仪式上发言</span>
                </div>
                <div class="left-box2">
                  <div></div>
                </div>
              </div>
              <div class="center"><span>
              </span></div>
              <div class="right"></div>
          </div>
          <div class="box wow fadeInUp">
              <div class="left">
              </div>
              <div class="center"><span>
              </span></div>
                <div class="right">
                  <div class="m-dashed"></div>
                  <div class="right-box1">
                  <h3>1996年 / 丙子 56岁</h3>
                  <h5 class="m-long-h5">5月，《秋韵》入选“湖南·中国工笔画艺术大展”，在中国美术馆展出。</h5>
                  <img class="m-picture10" src="../assets/licheng/picture10.png" alt=""><br>
                  <p class="m-long-h5">1997年7月7日中国美术馆收藏邹传安《秋韵》证书</p>
                </div>
              </div>
          </div>
          <div class="box wow fadeInUp" style="height:343px">
                <div class="left m-left-57-style">
                <div class="m-left-57-style1"></div>
                <div class="m-left-57-style2"></div>
                <div class="left-box1">
                  <h3>1997年 / 丁丑 57岁</h3>
                  <h5>5月，由娄底文联退休。</h5>
                  <img class="m-picture-55" src="../assets/licheng/picture11.png" alt="">
                  <br>
                  <span>*1997年5月邹传安退休证</span>
                </div>
                <div class="left-box2">
                  <div></div>
                </div>
              </div>
              <div class="center"><span>
              </span></div>
              <div class="right"></div>
          </div>
          <div class="box wow fadeInUp" style="height:250px">
              <div class="left"></div>
              <div class="center"><span>
              </span></div>
               <div class="right">
                  <div class="m-dashed"></div>
                  <div class="right-box1">
                  <h3>1998年 / 戊寅 58岁</h3>
                  <h5 class="m-long-h5">7月，受聘为湖南师范大学艺术学院美术系客座教授。</h5>
                  <img class="m-picture10" src="../assets/licheng/picture12.jpg" alt=""><br>
                  <p class="m-long-h5">*1998年7月28日湖南师范大学艺术学院聘为美术系客座教授</p>
                  <h5 style="margin-top:31px" class="m-long-h5">11月，受聘为湖南省文史研究馆馆员。</h5>
                  <div class="m-58-list">
                    <div v-for="(ele,eleindex) in researchData" :key="eleindex">
                      <img :src="ele.img" alt=""><br>
                      <span>{{ele.title}}</span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="box wow fadeInUp" style="height:364px">
              <div class="left">
                <div class="left-box1">
                  <h3>2001年 / 辛巳 61岁</h3>
                  <h5>3月，在深圳发起成立“心源工笔画研究会”。</h5>
                </div>
                <div class="left-box2">
                  <div></div>
                </div>
              </div>
              <div class="center"><span>
              </span></div>
              <div class="right"></div>
          </div>
          <div class="box wow fadeInUp" style="height:472px">
              <div class="left">
                  <div class="left-box1">
                  <h3>2005年 / 乙酉 65岁</h3>
                  <h5 class="m-long-h5">3月，《闲庭信步》等入选“当代国画优秀作品展——湖南作品展” ，在全国政协礼堂展出。</h5>
                  <img class="m-picture-55" src="../assets/licheng/picture15.png" alt="">
                  <br>
                  <span class="m-long-h5">*2005年3月1日“当代国画优秀作品展——湖南作品展”在全国政协礼堂展出  </span>
                  <img class="m-picture-55" src="../assets/licheng/picture16.png" alt="">
                  <br>
                  <span class="m-long-h5">*2005年3月1日时任全国政协主席贾庆林观看“当代国画优秀作品展——湖南作品展” </span>
                </div>
                <div class="left-box2">
                  <div></div>
                </div>
              </div>
              <div class="center"><span>
              </span></div>
              <div class="right m-right-2005-style">
                <div class="m-right-2005-style1"></div>
                <div class="m-right-2005-style2"></div>
              </div>
          </div>
          <div class="box wow fadeInUp m-box-height-2010">
              <div class="left m-left-2010-style">
                <div class="m-left-2010-style1"></div>
                <div class="m-left-2010-style2"></div>
                <div class="m-left-2010-style3"></div>
              </div>
              <div class="center"><span>
              </span></div>
               <div class="right">
                  <div class="m-dashed"></div>
                  <div class="right-box1">
                  <h3>2010年 / 庚寅 70岁</h3>
                  <h5>8月，广西美术出版社出版《尘程心证——邹传安艺术文献集》。</h5>
                  <img class="m-picture17" src="../assets/licheng/picture17.png" alt=""><br>
                  <p>*2010年邹传安照</p>
                  <h5 style="margin-top:31px" class="m-long-h5">10-11月，“居深履厚——邹传安从艺50年回顾展”先后在关山月美术馆、中国美术馆、湖南省博物馆展出。</h5>
                  <div class="m-70-list">
                    <div v-for="(ele,eleindex) in galleryData" :key="eleindex">
                      <img :src="ele.img" alt=""><br>
                      <span class="m-long-h5">{{ele.title}}</span>
                    </div>
                  </div>
                  <h5 style="margin-top:24px" class="m-long-h5">11月，中国美术馆收藏《古胄凌烟月上时》。</h5>
                  <img class="m-picture-55" style="margin-top:10px" src="../assets/licheng/picture18.png" alt=""><br>
                  <p class="m-long-h5">*2010年11月18日中国美术馆收藏 “古胄凌烟月上时”证书</p>
                </div>
              </div>
          </div>
          <div class="box wow fadeInUp" style="min-height:310px">
              <div class="left m-left-2011-style">
                <div class="m-left-2011-style1"></div>
                <div class="left-box1">
                  <h3>2011年 / 辛卯 71岁</h3>
                  <h5>2月，向湖南图书馆捐赠著作、书法等。</h5>
                  <img class="m-picture-55" src="../assets/licheng/picture19.jpg" alt="">
                  <br>
                  <span class="m-long-h5">*2011年2月20日邹传安向湖南图书馆捐赠仪式现场</span>
                </div>
                <div class="left-box2">
                  <div></div>
                </div>
              </div>
              <div class="center"><span>
              </span></div>
              <div class="right"></div>
          </div>
          <div class="box wow fadeInUp" style="min-height:315px">
              <div class="left"></div>
              <div class="center"><span>
              </span></div>
              <div class="right">
                  <div class="m-dashed"></div>
                  <div class="right-box1">
                  <h3>2013年 / 癸巳 73岁</h3>
                  <h5 class="m-long-h5">6月，向湖南省博物馆捐赠绘画、书法作品，画稿，瓷器样稿及书籍。</h5>
                  <img class="m-picture10" src="../assets/licheng/picture20.png" alt=""><br>
                  <p class="m-long-h5">*2013年8月28日邹传安向湖南省博物馆捐赠作品仪式现场</p>
                </div>
              </div>
          </div>
          <div class="box wow fadeInUp">
              <div class="left">
                <div class="left-box1">
                  <h3>2015年 / 乙未 75岁</h3>
                  <h5 class="m-long-h5">6月，岳麓书社出版《湖南省博物馆收藏大系——邹传安先生捐赠作品集》。</h5>
                </div>
                <div class="left-box2">
                  <div></div>
                </div>
              </div>
              <div class="center"><span>
              </span></div>
              <div class="right m-right-2015-style">
                <div class="m-right-2015-style1"></div>
                <div class="m-right-2015-style2"></div>
              </div>
          </div>
          <div class="box wow fadeInUp" style="height:350px">
              <div class="left m-left-2018-style">
                <div class="m-left-2018-style1"></div>
              </div>
              <div class="center"><span>
              </span></div>
              <div class="right">
                <div class="m-dashed"></div>
                  <div class="right-box1">
                  <h3>2018年 / 戊戌 78岁</h3>
                  <h5 class="m-long-h5">9月，深圳海天出版社出版《邹传安全集》。</h5>
                  <img class="m-picture10" src="../assets/licheng/picture21.png" alt=""><br>
                </div>
              </div>
          </div>
          <div class="box wow fadeInUp">
              <div class="left">
                <div class="left-box1">
                  <h3>2020年 / 庚子 80岁</h3>
                  <h5 class="m-long-h5">11月，“八秩回眸——邹传安书画艺术文献展”在长沙美术馆展出。</h5>
                </div>
                <div class="left-box2">
                  <div></div>
                </div>
              </div>
              <div class="center"><span>
              </span></div>
              <div class="right"></div>
          </div>
          <div class="box wow fadeInUp">
              <div class="left"></div>
              <div class="center"><span>
              </span></div>
                  <div class="right">
                <div class="m-dashed"></div>
                  <div class="right-box1">
                  <h3>2021年 / 辛丑  81岁</h3>
                  <h5 class="m-long-h5">1月，深圳海天出版社出版《工笔花鸟画技法》。</h5>
                  <h5 class="m-long-h5">4月，向湖南省博物馆捐赠绘画、书法作品，画稿、手稿文献及书籍。</h5>
                </div>
              </div>
          </div>
          <div class="box wow fadeInUp" style="height:289px">
              <div class="left m-left-2022-style">
                <div class="m-left-2022-style1"></div>
                <div class="m-left-2022-style2"></div>
                <div class="left-box1">
                  <h3>2022年 / 壬寅  82岁</h3>
                  <h5 class="m-long-h5">5月，“花魂鸟魄——邹传安艺术与文献展”在湖南省博物馆展出。</h5>
                </div>
                <div class="left-box2">
                  <div></div>
                </div>
              </div>
              <div class="center"><span>
              </span></div>
              <div class="right m-right-2022-style">
                <div class="m-right-2022-style1"></div>
                <div class="m-right-2022-style2"></div>
              </div>
          </div>
        </div>
      </div>
      <ul class="hong_zhua_ul">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
    <!-- 导航区域 -->
    <!-- <ul class="navs">
      <li :class="{ active: active === 0 }" @click="scrollTo(0)"></li>
      <li :class="{ active: active === 1 }" @click="scrollTo(1)"></li>
      <li :class="{ active: active === 2 }" @click="scrollTo(2)"></li>
      <li :class="{ active: active === 3 }" @click="scrollTo(3)"></li>
      <li :class="{ active: active === 4 }" @click="scrollTo(4)"></li>
      <li :class="{ active: active === 5 }" @click="scrollTo(5)"></li>
      <li :class="{ active: active === 6 }" @click="scrollTo(6)"></li>

    </ul> -->
  </div>
</template>

<script>
import $ from 'jquery'
import WOW from 'wowjs'
import MenuTemp from '@/views/components/MenuTemp'

export default {
  props: {},
  components: { MenuTemp },
  data () {
    return {
      navTag: false,
      active: 0, // 当前激活的导航索引
      tuxiangData: [
        {
          img: require('../assets/licheng/tuxiang80.png'),
          title: '*1991年9月25日，“邹传安画展”于湖南师范大学艺术学院美术系开幕'
        },
        {
          img: require('../assets/licheng/tuxiang81.png'),
          title: '*1991年9月25日于“邹传安画展”'
        },
        {
          img: require('../assets/licheng/tuxiang82.png'),
          title: '*1991年邹传安为湖南师范大学美术系学生讲座'
        },
        {
          img: require('../assets/licheng/tuxiang83.png'),
          title: '**1991年9月湖南大学张举毅教授率其学生观“邹传安画展”'
        },
        {
          img: require('../assets/licheng/tuxiang84.png'),
          title: '*1991年邹传安为湖南师范大学美术系学生签名'
        }
      ],
      researchData: [
        {
          img: require('../assets/licheng/picture13.png'),
          title: '*1998年11月2日湖南文史研究馆馆员授聘仪式'
        },
        {
          img: require('../assets/licheng/picture14.jpg'),
          title: '*1998年11月2日湖南省人民政府聘为湖南省文史研究馆馆员聘书'
        }
      ],
      galleryData: [
        {
          img: require('../assets/licheng/tuxiang103.png'),
          title: '*2010年11月5日“居深履厚——邹传安从艺50年回顾展”在中国美术馆开幕'
        },
        {
          img: require('../assets/licheng/tuxiang104.png'),
          title: '*2010年11月5日中国美术馆“居深履厚——邹传安从艺50年回顾展”中与陈传席合影'
        },
        {
          img: require('../assets/licheng/tuxiang106.png'),
          title: '*2010年11月14日“居深履厚——邹传安从艺五十年回顾展”在湖南省博物馆开幕'
        },
        {
          img: require('../assets/licheng/tuxiang105.png'),
          title: '*2010年11月5日中国美术馆“居深履厚——邹传安从艺50年回顾展”学术研讨会'
        }
      ]
    }
  },
  mounted () {
    // 监听滚动事件
    window.addEventListener('scroll', this.onScroll, false)
    $('html,body').scrollTop(0)
    new WOW.WOW().init()
    $('img').attr({ oncontextmenu: 'return false;', ondragstart: 'return false;' })
  },
  destroy () {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    changeShowMenu () {
      this.$refs.refMenuTemp.changeShowMenu()
    },
    // 滚动监听器
    onScroll () {
      // 获取当前文档流的 scrollTop
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // console.log('scrollTop...', scrollTop)
      if (scrollTop > 0) {
        // 获取所有锚点元素
        const navContents = document.querySelectorAll('.content >div')
        // 所有锚点元素的 offsetTop
        const offsetTopArr = []
        navContents.forEach((item) => {
          offsetTopArr.push(item.offsetTop)
        })
        // console.log('scrollTop...', scrollTop)
        // 定义当前点亮的导航下标
        let navIndex = 0
        for (let n = 0; n < offsetTopArr.length; n++) {
          // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
          // 那么此时导航索引就应该是n了
          if (scrollTop >= offsetTopArr[n]) {
            navIndex = n
          }

          if (
            scrollTop + document.documentElement.clientHeight ===
            document.documentElement.scrollHeight
          ) {
            navIndex = offsetTopArr.length - 1
          }
        }
        this.active = navIndex
      }
    },
    // 跳转到指定索引的元素
    scrollTo (index) {
      console.log(index)
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      const targetOffsetTop = document.querySelector(
        `.content div:nth-child(${index + 1})`
      ).offsetTop
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉，步子迈大了会扯到蛋
      const STEP = 50
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp()
      } else {
        // 往下滑
        smoothDown()
      }

      // 定义往下滑函数
      function smoothDown () {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP
          } else {
            scrollTop = targetOffsetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown)
        }
      }

      // 定义往上滑函数
      function smoothUp () {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP
          } else {
            scrollTop = targetOffsetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          requestAnimationFrame(smoothUp)
        }
      }
    }
  }
}
</script>

<style scoped lang="less">

.li_cheng {
  // width: 100vw;
  background: #f4f3f1;
  // height: 4600px;
  position: relative;
  overflow: hidden;

  .content_item {
    span {
      color: #999;
    }
  }

  .hander_box {
    display: flex;
    justify-content: space-between;
    position: fixed;
    padding-top: 22px;
    padding-bottom: 22px;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1000;

    .hander_r {
      font-size: 36px;
      color: #5c5c5c;
      padding-right: 68px;
    }

    .hander_l {
      padding-left: 68px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 54px;
        height: 15px;
      }

      span {
        font-size: 21px;
        color: #5c5c5c;
        padding-left: 20px;
        display: block;
      }
    }
  }

  .hong_zhua_gr1 {
    z-index: 10;
    position: relative;
    width: 100%;
    // padding-bottom: 100px;

    .hongzhua_item {
      padding-left: calc(100vw / 6);
      position: relative;
      top: -101px;

      > h4 {
        font-size: 87px;
        font-weight: 600;
        color: #5c5c5c;
      }

      > ol {
        display: flex;
        flex-wrap: wrap;
        width: calc((100vw / 6) * 4);

        .li_1 {
          padding-bottom: 10%;
        }

        .li_5 {
          > img {
            width: calc(100vw / 6);
            margin: 30px 0 15px 0;
          }
        }

        .li_7 {
          padding-top: 87px;
        }

        li {
          width: calc(100vw / 6);

          &:nth-child(5) {
            margin-right: calc(100vw / 6);
          }

          > span {
            font-size: 21px;
            font-weight: 400;
          }

          > p {
            font-size: 21px;
            font-weight: 500;
            color: #5c5c5c;
          }
        }
      }
    }
  }

  .hong_zhua_title {
    position: relative;

    .hong_zhua_title_box {
      width: 159px;
      height: 159px;
      background: #eac06b;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }

    > span {
      height: 68px;
      font-size: 48px;
      font-weight: 600;
      color: #5c5c5c;
      top: 68px;
      position: absolute;
      left: 63px;
    }
  }

  .hong_zhua_ul {
    width: 100vw;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    display: flex;

    li {
      border-right: 1px solid #e6e5e3;
      width: calc(100vw / 6);
      height: 100%;
      box-sizing: border-box;

      &:last-of-type {
        border: none;
      }
    }
  }
}

/* 内容区的样式 */
.content {
  padding-top: 90px;
  // background-color: white;
  // width: 500px;
}

/* 导航栏的样式 */
.navs {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100000;
  right: 10px;
}

.navs li {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #b1b1b1;
  margin-bottom: 50px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
}

/* 当导航被点亮后改变颜色 */
.navs .active {
  background: #5c5c5c;
}

/** 时间轴*/
.content-container{
  width: 100vw;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 999;

}
  .wrap{
     width: calc(100vw / 3 *2)
    // top: 100px;
  }

  .box{
      width: 100%;
      height: 102px;
      margin-bottom: 50px;
      display: flex;
      justify-content: space-between;
  }
  .right{
    width: calc(100vw / 3);

  }
  .left{
    // background: sandybrown;
    display: flex;
    min-height: 150px;
    width: calc(100vw / 3);
  }
  .left-style{
    position: relative;
    .left-style1{
      position: absolute;
      left:-212px;
      top: 430px;
      width: 106px;
      height: 192px;
      background: #B4DBCF;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .left-style2{
      position: absolute;
      top: 580px;
      left: -106px;
      width: 106px;
      height: 129px;
      background: #5C5C5C;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }

  // 43岁
  .m-left-1983-style{
    position: relative;
    .m-left-1983-style1{
      position: absolute;
      width: 106px;
      height: 106px;
      left: -214px;
      background: #5C5C5C;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .m-left-1983-style2{
      position: absolute;
      left: -214px;
      top: 106px;
      width: 106px;
      height: 192px;
      background: #EAC06B;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
  // 55岁
  .m-left-55-style{
    position: relative;
    .m-left-55-style1{
      position: absolute;
      left: -106px;
      top: -166px;
      width: 106px;
      height: 126px;
      background: #B4DBCF;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .m-left-55-style2{
      position: absolute;
      left: -212px;
      top: -40px;
      width: 106px;
      height: 170px;
      background: #EAC06B;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
  // 57岁
   .m-left-57-style{
    position: relative;
    .m-left-57-style1{
      position: absolute;
      left: -106px;
      top: 416px;
      width: 106px;
      height: 126px;
      background: #EAC06B;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .m-left-57-style2{
      position: absolute;
      left: -212px;
      top: 540px;
      width: 106px;
      height: 170px;
      background: #B4DBCF;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }

  // 70岁
  .m-left-2010-style{
    position: relative;
    .m-left-2010-style1{
      position: absolute;
      top: 508px;
      left: -106px;
      width: 106px;
      height: 154px;
      background: #5C5C5C;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .m-left-2010-style2{
      position: absolute;
      top: 608px ;
      width: 106px;
      height: 211px;
      background: #B4DBCF;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .m-left-2010-style3{
      position: absolute;
      top: 819px;
      width: 106px;
      height: 86px;
      background: #EAC06B;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }

  // 71岁
  .m-left-2011-style{
    position: relative;
    .m-left-2011-style1{
      position: absolute;
      top: 150px;
      left: -212px;
      width: 106px;
      height: 106px;
      background: #EAC06B;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
  // 78岁
  .m-left-2018-style{
    position: relative;
    .m-left-2018-style1{
      position: absolute;
      left: -106px;
      width: 106px;
      height: 186px;
      background: #B4DBCF;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
  // 82
  .m-left-2022-style{
    position: relative;
    .m-left-2022-style1{
      position: absolute;
      left: -214px;
      width: 106px;
      height: 153px;
      background: #EAC06B;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .m-left-2022-style2{
      position: absolute;
      top: 153px;
      left: -214px;
      width: 106px;
      height: 68px;
      background: #5C5C5C;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
  /**时间轴左边样式 */
  .left-box1{
    width: calc(100vw / 6);
    // background: pink;
    text-align: left;
    height: 100%;

    >img{
      margin-top: 25px;
      width: calc(100vw / 6 - 20px);
    }
    >span{
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #5C5C5C;
      line-height: 20px;
      margin-top: 4px;
    }
    .m-picture1{
      width: calc(100vw / 3.9);
    }
    .m-picture2{
      width: calc(100vw / 3.9);
      margin-top: 10px;
    }
    .m-51-h5{
       width: calc(100vw / 3 * 0.8)
    }
    // 1991年/辛末51岁
    .m-tuxiang{
      width: calc(100vw / 3);
      min-height: 200px;
      display: flex;
      flex-wrap: wrap;
     >div{
      //  width: calc(100vw / 6);
      margin-left: 20px;
      >img{
        width: calc(100vw / 6 * 0.7);
        margin-top:10px;
      }
      >span{
        display: inline-block;
        width: calc(100vw / 6 * 0.8);
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #5C5C5C;
        line-height: 20px;
        margin-top: 4px;
      }
     }
    }

    // 55岁
    .m-picture-55{
      width: calc(100vw / 3 * 0.8);
    }
  }

  .left-box2{
    width: calc(100vw /6  - 20px);
    position: relative;
    // background: blue;
    >div{
      position: absolute;
      left: -135px;
      width: calc(100vw / 6 *2 - 205px);
      border-bottom:1px dashed #ccc;
      margin-top: 13px;
    }
  }
  .left-four{
    display: flex;
    justify-content: space-between;
  }
  .left-box1-four{
    width: calc(100vw / 3.8);
    text-align: right;
  }
  .left-box2-four{
    width: 94px;
    margin-right: 5px;
    position: relative;
    >div{
      position: absolute;
      left: -40px;
      width: 130px;
      border-bottom:1px dashed #ccc;
      margin-top: 13px;
      }
  }
  .left-box1-47{
   margin-left: calc(100vw / 6 * 0.4);
  }
  .left-box2-47{
    width: calc(100vw / 6 * 0.4);
    position: relative;
    >div{
      position: absolute;
      left: -130px;
      width: calc(100vw / 6 * 0.93);
      border-bottom:1px dashed #ccc;
      margin-top: 13px;
    }
  }
  /**时间轴右边样式 */
  .right{
    display: flex;
  }
  // 16岁
  .m-right-1956-style{
    position: relative;
    .m-right-1956-style1{
      position: absolute;
      top: -220px;
      left: -13px;
      width: 106px;
      height: 129px;
      background: #B4DBCF;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .m-right-1956-style2{
      position: absolute;
      right: 0;
      top: -100px;
      width: 106px;
      height: 192px;
      background: #EAC06B;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .m-right-1956-style3{
      position: absolute;
      right: -105px;
      top: 80px;
      width: 106px;
      height: 106px;
      background: #B4DBCF;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
  .m-right-53{
    position: relative;
    .m-right-53-style{
      position: absolute;
      right:-106px;
    }
    .m-right-53-style1{
      width: 106px;
      height: 106px;
      background: #B4DBCF;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .m-right-53-style2{
      width: 106px;
      height: 180px;
      background: #EAC06B;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
  // 75岁
  .m-right-2015-style{
    position: relative;
    display: flex;
    flex-direction: column;
    .m-right-2015-style1{
      position: absolute;
      top: -81px;
      right: -215px;
      width: 106px;
      height: 151px;
      background: #B4DBCF;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .m-right-2015-style2{
      position: absolute;
      top: 70px;
      right: -215px;
      width: 106px;
      height: 79px;
      background: #EAC06B;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
    // 1998年 / 戊寅 58岁
.m-58-list{
      width: calc(100vw / 2.5);
      min-height: 200px;
      display: flex;
      justify-content: space-between;
     >div{
       width: calc(100vw / 5.5);
      >img{
        width: calc(100vw / 5.5 );
        margin-top:10px;
      }
      >span{
        display: inline-block;
        width: calc(100vw / 6 * 0.9);
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #5C5C5C;
        line-height: 20px;
        margin-top: 4px;
      }
     }
}
.m-70-list{
      width: calc(100vw / 2.5);
      min-height: 200px;
      display: flex;
      flex-wrap: wrap;

     >div{
       width: calc(100vw / 5.5);
      >img{
        width: calc(100vw / 5.5 );
        margin-top:10px;
      }
      >span{
        display: inline-block;
        width: calc(100vw / 6 );
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #5C5C5C;
        line-height: 20px;
        margin-top: 4px;
      }
     }
      >div:nth-child(even) >img {
        margin-left: 28px;
      }
      >div:nth-child(even) >span {
        margin-left: 28px;
      }
}

  .m-dashed{
    width: 26px;
    border-top:1px dashed #ccc;
    margin-top: 13px;
    margin-left: 5px;
    margin-right: 5px;
    // background: pink;
  }
  .right-box1{
    width: calc(100vw / 6 - 20px);
    // background: pink;
    text-align: left;
    height: 100%;
    .m-picture3{
      width: calc(100vw / 4);
      margin-top: 24px;
    }
    .m-picture10{
      width: calc(100vw / 4 * 0.8);
      margin-top: 24px;
    }
    .m-picture17{
      width: calc(100vw / 6 - 24px);
      margin-top: 24px;
    }
    p{
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #5C5C5C;
      line-height: 20px;
    }

    .right-box1-list{
      width: calc(100vw / 4);
      height: 200px;
      display: flex;
      justify-content: space-between;
      >div{
        >img{
          // width:calc(100vw / 6 * 0.8);
          margin: 10px 0;
        }
        >span{
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #5C5C5C;
          line-height: 20px;
          margin-top: 4px;
        }
      }
      }
  }
  .m-right-46-style{
    position: relative;
      .m-yellow-right{
        position: absolute;
        top: -100px;
        right: -105px;
        width: 106px;
        height: 157px;
        background: #EAC06B;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
  }
// 2005
  .m-right-2005-style{
    position: relative;
    display: flex;
    flex-direction: column;
    .m-right-2005-style1{
      position: absolute;
      right: -105px;
      top: 146px;
      width: 106px;
      height: 86px;
      background: #5C5C5C;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .m-right-2005-style2{
      position: absolute;
      width: 106px;
      height: 167px;
      right: -105px;
      top: 226px;
      background: #EAC06B;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
  // 82岁
  .m-right-2022-style{
    position: relative;
    .m-right-2022-style1{
      position: absolute;
      right: 0;
      bottom: -40px;
      width: 106px;
      height: 56px;
      background: #B4DBCF;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .m-right-2022-style2{
      position: absolute;
      right: -105px;
      bottom: -40px;
      width: 106px;
      height: 197px;
      background: #EAC06B;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
  .right-box2{
    width: calc(100vw / 6 - 80px);
    >img{
       width: calc(100vw / 6 - 60px);
      //  height: 230px;
      margin-left: 40px;
    }
    p{
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #5C5C5C;
      line-height: 20px;
      margin-left: 60px;
    }
    .m-green-box{
      width: 106px;
      height: 71px;
      background: #B4DBCF;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin-left: 60px;
      margin-top: 25px;
    }
  }
  // .box:nth-child(even) .left{
  //     background-color: rgba(255,255,255,.4);
  //     box-shadow: 0 0 10px rgba(0,0,0,.3);
  // }
  // .box:nth-child(odd) .right{
  //     background-color: rgba(255,255,255,.4);
  //     box-shadow: 0 0 10px rgba(0,0,0,.3);
  // }
  .center{
      width:26px;
      height: 26px;
      border-radius: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #D3D3D3;
      background: #fff;
  }
  .center>span{
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background: #909090;;
  }

  h3{
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-top: 3px;
  }
  h5{
    // width: calc(100vw / 3 * 0.8);
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #5C5C5C;
    line-height: 20px;
    margin-top: 10px;
  }
  .m-long-h5{
    width: calc(100vw / 3 * 0.8);
    display: inline-block;
  }
  .m-box-height-2010{
  height: 1200px;
  }
  .m-picture-55{
    width: calc(100vw / 3 * 0.8);
  }
</style>
