export const imgList =
  [{ id: '1', url: 'ab553db3e638836f5f01a3ecf948fb14.jpg', name: '邬惕予题跋邹传安《临现代名家画集》手稿' },
    { id: '2', url: '6b6972309457fa8b92c63f20c86c1ac6.jpg', name: '时任湖南省美协主席陈白一先生致邹传安先生信' },
    { id: '3', url: '39ad314d6077e3f101162a1248bc22ea.jpg', name: '时任《中国画》主编、中国美术家协会北京分会副主席的潘絜兹先生致邹传安先生信' },
    { id: '4', url: '80c25c5924fd7ddfdc0fec3b374667b0.jpg', name: '时任湖南省美协秘书长的王金星先生致邹传安先生信' },
    { id: '5', url: 'bd4f2bdb7b52b7639d91c89eb734acd5.jpg', name: '画家易图境先生致邹传安先生信' },
    { id: '6', url: '24080b9a6d9366fb4347d6f930187cf4.jpg', name: '湖南省美协主席陈白一先生致邹传安先生短信' },
    { id: '7', url: 'cd648a144e3362305b8aed6486979bdd.jpg', name: '时任湖南省美协主席陈白一先生致邹传安先生约稿参展函' },
    { id: '8', url: 'ce9830851eea1b0591efaf368a1bbb7c.jpg', name: '时任湖南省美协副主席的钟增亚先生致邹传安先生信' },
    { id: '9', url: 'fuxinzhinigao1-2.jpg', name: '邹传安先生致某作者复信之拟稿' },
    { id: '10', url: '792072ba90edb8f0a114614fe653f0ce.jpg', name: '林家湖先生来信及邹传安先生回信' },
    { id: '11', url: '1baf70be2c093a0606ce19c4fcfca22d.jpg', name: '案头散录之一·艺术观' },
    { id: '12', url: 'f0166701a9585ddd13b23b71fc832956.jpg', name: '《本人艺术思想》手稿' },
    { id: '13', url: 'shuwen1.jpg', name: '《书问》手稿' }
  ]

export default imgList
