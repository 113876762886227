
<template>
  <div class="home_list">
    <!-- 内容区域 slideInUp -->
    <div class="content">
      <div class="content_item" id="content_item1">
        <HomeHander />
      </div>
      <div class="content_item">
        <CVideo />
      </div>
      <div class="content_item">
        <OSwiper />
      </div>
      <div class="content_item">
        <Item01 @goInfoById="goInfoById" />
      </div>
      <div class="content_item">
        <Item02 @goInfoById="goInfoById" />
      </div>
      <div class="content_item">
        <Item03 @goInfoById="goInfoById" />
      </div>
      <div class="content_item">
        <Item04 @goInfoById="goInfoById" />
      </div>
      <div class="content_item">
        <HongZao @goInfoById="goInfoById" />
      </div>
      <div class="content_item">
        <Manuscript @goInfoById="goInfoById" />
      </div>
      <div class="content_item">
        <FooterItem01 @goInfoById="goInfoById" />
      </div>
      <div class="content_item">
        <FooterItem02 @goInfoById="goInfoById" />
      </div>
      <div id="content_item">
        <IndexFooter @goTop="goToTop" />
      </div>
    </div>
    <!-- 导航区域 -->
    <ul class="navs">
      <li v-for="(item,index) in 11" :key="index" :class="{ active: active === index }" @click="scrollTo(index)"></li>
      <!-- <li :class="{ active: active === 1 }" @click="scrollTo(1)"></li>
      <li :class="{ active: active === 2 }" @click="scrollTo(2)"></li>
      <li :class="{ active: active === 3 }" @click="scrollTo(3)"></li>
      <li :class="{ active: active === 4 }" @click="scrollTo(4)"></li>
      <li :class="{ active: active === 5 }" @click="scrollTo(5)"></li>
      <li :class="{ active: active === 6 }" @click="scrollTo(6)"></li> -->
    </ul>
  </div>
</template>

<script>
import WOW from 'wowjs'
import HomeHander from './components/Hander.vue'
import CVideo from './components/CVideo.vue'
import FooterItem01 from './components/FooterItem01.vue'
import FooterItem02 from './components/FooterItem02.vue'
import HongZao from './components/HongZao.vue'
import IndexFooter from './components/IndexFooter.vue'
import Manuscript from './components/Manuscript.vue'
import Item01 from './components/item01.vue'
import Item02 from './components/item02.vue'
import Item03 from './components/item03.vue'
import Item04 from './components/item04.vue'
import OSwiper from './components/swiper.vue'

export default {
  props: {},
  data () {
    return {
      active: 0 // 当前激活的导航索引
    }
  },
  components: {
    HomeHander,
    FooterItem01,
    FooterItem02,
    IndexFooter,
    HongZao,
    Manuscript,
    Item01,
    Item02,
    Item03,
    Item04,
    CVideo,
    OSwiper
  },
  mounted () {
    // 监听滚动事件
    window.addEventListener('scroll', this.onScroll, false)
    setTimeout(() => {
      new WOW.WOW().init()
    }, 1500)
  },
  destroy () {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    goToTop () {
      // 返回顶部
      this.scrollTo(0)
    },
    goInfoById (query) {
      this.$router.push({ path: '/list', query: query })
    },
    // 滚动监听器
    onScroll () {
      // 获取当前文档流的 scrollTop
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      console.log('scrollTop...', scrollTop)
      // 获取所有锚点元素
      const navContents = document.querySelectorAll('.content >div')
      // 所有锚点元素的 offsetTop
      const offsetTopArr = []
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop)
      })
      console.log('scrollTop...', scrollTop)
      // 定义当前点亮的导航下标
      let navIndex = 0
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n
        }

        if (
          scrollTop + document.documentElement.clientHeight ===
            document.documentElement.scrollHeight
        ) {
          navIndex = offsetTopArr.length - 1
        }
      }
      this.active = navIndex
    },
    // 跳转到指定索引的元素
    scrollTo (index) {
      console.log(index)
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      const targetOffsetTop = document.querySelector(
        `.content div:nth-child(${index + 1})`
      ).offsetTop
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉，步子迈大了会扯到蛋
      const STEP = 50
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp()
      } else {
        // 往下滑
        smoothDown()
      }
      // 定义往下滑函数
      function smoothDown () {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP
          } else {
            scrollTop = targetOffsetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown)
        }
      }
      // 定义往上滑函数
      function smoothUp () {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP
          } else {
            scrollTop = targetOffsetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          requestAnimationFrame(smoothUp)
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
  // .all_work_btn {
  //   span {
  //     color: #fff;
  //     font-size: 45px;
  //     cursor: pointer;
  //     &:hover{
  //       opacity: .75;
  //     }
  //     &:active{
  //       position: relative;
  //       top: 1px;
  //     }
  //   }
  // }
.home_list {
  // width: 100vw;
  background: #f4f3f1;
  // height: 4600px;
  position: relative;
  overflow: hidden;
}
/* 内容区的样式 */
.content {
  // background-color: white;
  // width: 500px;
}
/* 导航栏的样式 */
.navs {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100000;
  right: 10px;
}
.navs li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #b1b1b1;
  margin-bottom: 30px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
}
/* 当导航被点亮后改变颜色 */
.navs .active {
  background: #5c5c5c;
}

//
.btn_hover {
  &:hover {
    cursor: pointer;
    transition: all 1s;
    background: orange;
    img {
      width: 62px;
    }
  }
  &:active {
    position: relative;
    top: 1px;
  }
}
.full_page {
  width: calc(100vw - 16px);
  overflow-x: hidden;
  // ::-webkit-scrollbar {display:none}
}
.hander_box {
  display: flex;
  justify-content: space-between;
  position: fixed;
  padding-top: 22px;
  padding-bottom: 22px;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  .hander_r {
    font-size: 36px;
    color: #5c5c5c;
    padding-right: 68px;
  }
  .hander_l {
    padding-left: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 54px;
      height: 15px;
    }
    span {
      font-size: 21px;
      color: #5c5c5c;
      padding-left: 20px;
      display: block;
    }
  }
}
@keyframes scaleKay2 {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  20% {
    opacity: 0.4;
    transform: scale(0.6);
  }
  70% {
    opacity: 0.78;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes scaleKay {
  0% {
    opacity: 0;
    transform: scale(2.5);
  }
  // 60%{
  //   opacity: 0.4;
  //   transform: scale(1.8);
  // }
  // 70%{
  //   opacity: 0.75;
  //   transform: scale(1.5);
  // }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.animation2 {
  animation-name: scaleKay2; // 动画名称
  animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
  animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
  animation-iteration-count: 1; //  动画播放次数，infinite：一直播放
  animation-duration: 1s; // 动画完成时间
}
@iterations: 50;
.span-loop (@i) when (@i > 0) {
  .delay_@{i} {
    @delay: (@i)/10;
    animation-delay: ~"@{delay}s";
  }
  .duration_@{i} {
    @delay: (@i)/1;
    animation-duration: ~"@{delay}s";
  }
  .span-loop(@i - 1);
}
.span-loop (@iterations);
.animation1 {
  position: relative;
  z-index: 99;
  animation-name: scaleKay; // 动画名称
  animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
  // animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
  // animation-delay: 0s; // 动画延迟时间
  animation-iteration-count: 1; //  动画播放次数，infinite：一直播放
  // animation-duration: 2s; // 动画完成时间
}
</style>
