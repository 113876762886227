<template>
  <div class="li_cheng">
     <div @click.stop="changeShowMenu" :class="navTag ? 'navTag':null" class="bg_div_right">
        <span>导航</span>
        <img v-if="!navTag" class="open" src="../views/components/image/nav/down.png" alt="" srcset="" />
        <img v-else class="close" src="../views/components/image/nav/close.png" alt="" srcset="" />
    </div>
    <div class="hander_box">
      <div class="hander_l" @click.stop="changeShowMenu">
        <!-- <img src="../assets/mylist/list.png" alt="" srcset="" />
        <span style="color: #5c5c5c">导航</span> -->
      </div>
      <p class="hander_r wow fadeInUp fadeIn"></p>
    </div>
    <MenuTemp v-model="navTag" ref="refMenuTemp" :type="2"></MenuTemp>

    <div class="page_list">
      <div class="page_list_left">
        <div class="hong_zhua_title wow fadeInUp fadeIn">
          <div class="hong_zhua_title_box"></div>
          <span>捐赠收藏</span>
        </div>
        <div class="page1_text">
          <span data-wow-delay="1s" class="wow fadeInUp fadeIn">概述</span>
          <p data-wow-delay="1.3s" class="wow fadeInUp fadeIn">
            2013年，邹传安先生与湖南省博物馆签订捐赠协议，旨在分批捐赠其代表作、画稿、手稿等完整的艺术档案。迄今为止，共捐赠工笔花鸟画、写意画、书法、瓷器设计稿、写生稿、画稿、手稿文献等共计1198件，书籍25种，这是湖南省博物馆建馆以来接受的最大一批个人捐赠。
</p>
          <!-- <p class="page1_name wow fadeInUp fadeIn">—— 邹传安先生</p> -->
        </div>
      </div>
      <div class="page_list_right">
        <img
          data-wow-delay="1s"
          class="wow fadeInUp fadeIn"
          src="../assets/work/page1-new.png"
          alt=""
          srcset=""
        />
        <!-- <img
          data-wow-delay="1.6s"
          class="wow fadeInUp fadeIn"
          src="../assets/work/page1_2.png"
          alt=""
          srcset=""
        /> -->
      </div>
    </div>
    <!-- WorkManuscript -->
    <!-- <WorkManuscript :imgList="imgList" :key="imgList.length" /> -->

    <div class="listing">
      <div class="listing_title wow fadeInUp fadeIn">捐赠清单</div>
      <!-- <div class="listing_right_bg wow fadeInUp fadeIn"></div> -->
      <div class="listing_left_bg wow fadeInUp fadeIn"></div>
      <el-table
        size="small"
         ref="table"
        class="wow fadeInUp fadeIn table"
        :data="tableData"
        height="800"
      >
        <el-table-column label="序号" type="index" width="80">
           <template slot-scope="scope">
                    <span :ref="scope.row.id==1199 ?'ref1199':null" :class="scope.row.id==1199 ? 'class_1199':''">{{scope.row.id}}</span>
                </template>
        </el-table-column>
        <el-table-column prop="name" :label="tableChange ? '名称':'书名'">
             <template slot-scope="scope">
                <span :class="'scope_name'+scope.row.id">{{scope.row.name}}</span>
            </template>
         </el-table-column>
        <el-table-column prop="type" label="类别"> </el-table-column>
        <el-table-column prop="yaer" :label="tableChange ? '年代':'出版时间'"> </el-table-column>
        <el-table-column prop="zhidi" :label="tableChange ? '质地':'版次'"> </el-table-column>
        <el-table-column prop="size" :label="tableChange ? '尺寸':'出版社'" > </el-table-column>
        <el-table-column prop="num" label="数量"> </el-table-column>
      </el-table>
    </div>

    <div class="interview">
      <div class="interview_title wow fadeInUp fadeIn">人物采访视频</div>
      <div class="interview_bg1 wow fadeInUp fadeIn"></div>
      <div class="interview_bg2 wow fadeInUp fadeIn"></div>
      <div class="interview_bg3 wow fadeInUp fadeIn"></div>
      <div class="interview_bg4 wow fadeInUp fadeIn"></div>
      <el-row :gutter="60" class="interview_content">
        <el-col :span="14" class="interview_content_video wow fadeInUp fadeIn">
          <div class="video_box" id="page2_an_1">
            <video ref="videoPlayer" class="video" controls>
              <source
                :src="`${videoUrl}/ftp/sanrenfangtan.mp4`"
                type="video/mp4"
              />
            </video>
            <div class="video_play" v-if="isVideo" @click="toPay">
              <img src="./components/image/video_play.png" alt="" srcset="" />
            </div>
          </div>
        </el-col>
        <el-col :span="10" class="interview_content_info">
          <div class="interview_content_info_bg wow fadeInUp fadeIn"></div>
          <div class="info_box">
            <div class="info_box_title wow fadeInUp fadeIn">专题访谈</div>
            <div class="info_box_text wow fadeInUp fadeIn">
              中国书协副主席、湖南省文联主席鄢福初，湖南省博物馆馆长段晓明对话邹传安先生，为您讲述捐赠背后的故事。
            </div>
            <!-- <div class="info_box_date wow fadeInUp fadeIn">—— 2019/08/23</div> -->
          </div>
        </el-col>
      </el-row>
      <div class="interview_footer">
        <!-- <div>01 / 06</div> -->
        <!-- <img class="interview_footer_img" src="../assets/work/icon_right.png" alt=""> -->
      </div>
    </div>
    <div id="content_item">
      <IndexFooter @goTop="goTop" :show="false" />
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import WOW from 'wowjs'
import WorkManuscript from './components/WorkManuscript.vue'
import IndexFooter from './components/IndexFooter.vue'
import jsonData from './components/data/data'
import MenuTemp from '@/views/components/MenuTemp'
import { imgList } from './components/image/picture/picture.js'
import { videoUrl } from '@/config/index.js'
export default {
  props: {},
  data () {
    return {
      navTag: false,
      tableChange: true,
      imgList: [],
      isVideo: true,
      tableData: jsonData,
      videoUrl
    }
  },
  created () {
    this.imgList = imgList
  },
  mounted () {
    $('html,body').scrollTop(0)
    new WOW.WOW().init()
    $('img').attr({
      oncontextmenu: 'return false;',
      ondragstart: 'return false;'
    })
    this.bindMouseWheel()
  },
  destroy () {},
  methods: {
    bindMouseWheel () {
      // $('.listing .el-table__row').each((v) => {
      //   console.log($(this))
      //   console.log('height:', $(this).outerHeight())
      // })
      this.dom = this.$refs.table.bodyWrapper

      this.dom.addEventListener('scroll', () => {
        // const clasS1199 = document.querySelector('.class_1199')
        // console.log('clasS1199...', this.$refs.ref1199.scrollTop)
        // 滚动距离
        const scrollTop = this.dom.scrollTop
        // console.log('scrollTop...', scrollTop)
        if (scrollTop > 1199 * 90) {
          this.tableChange = false
        } else {
          this.tableChange = true
        }
        // console.log('scrollTop...', scrollTop)
        // // 变量windowHeight是可视区的高度
        const windowHeight = this.dom.clientHeight || this.dom.clientHeight
        // 变量scrollHeight是滚动条的总高度
        const scrollHeight = this.dom.scrollHeight || this.dom.scrollHeight
        // console.log(scrollTop + windowHeight > scrollHeight)
        // console.log('scrollTop', scrollTop + 'windowHeight', windowHeight + 'scrollHeight', scrollHeight)
        if (scrollTop + windowHeight === scrollHeight) {
          // 获取到的不是全部数据 当滚动到底部 继续获取新的数据
          // if (!this.allData) this.getMoreLog()
          // console.log('scrollTop', scrollTop + 'windowHeight', windowHeight + 'scrollHeight', scrollHeight)
        }
      })
    },
    changeShowMenu () {
      this.$refs.refMenuTemp.changeShowMenu()
    },
    goTop () {
      $('body, html').animate({ scrollTop: 0 })
    },
    toPay () {
      console.debug('this.$refs.videoPlayer')
      console.debug(this.$refs.videoPlayer)
      this.isVideo = false
      this.$refs.videoPlayer.paused
        ? this.$refs.videoPlayer.play()
        : this.$refs.videoPlayer.pause()
    }
  },
  components: {
    MenuTemp,
    WorkManuscript,
    IndexFooter
  }
}
</script>

<style lang="less">
.li_cheng {
  width: 100%;
  background: #f4f3f1;
  position: relative;
  overflow: hidden;
  overflow-x: hidden;
  .scope_name1213,.scope_name1198 ,.scope_name1142,.scope_name1143,.scope_name1144,.scope_name1145,.scope_name1146{
    font-size: 14px;
  }
  @iterations: 1198;
  .scope_name (@i) when (@i > 1142) {
    .scope_name@{i} {
      font-size: 12px;
    }
    .scope_name(@i - 1);
  }
  .scope_name (@iterations);
  .scope_name1147,.scope_name1148 {
    font-size: 12px;
  }
  .el-table__header tr,
    .el-table__header th {
      padding: 0;
      height: 90Px !important;
  }
  .el-table__body tr,
    .el-table__body td {
      padding: 0;
      height: 90Px !important;
  }
 ::-webkit-scrollbar{
   width:10px;
   height:8px;
   margin-right: 3px;
   background: #fff;
}
::-webkit-scrollbar-thumb{
   height:150px;
   outline-offset:-2px;
   -webkit-border-radius:4px;
   background: #878787;
}
  .page_list {
    padding-top: 90px;
    display: flex;
    height: 100vh;
    padding-bottom: 110px;

    .page_list_left {
      width: 44vw;

      .page1_text {
        padding-left: 159px;
        padding-top: 337px;

        > span {
          font-size: 42px;
          font-weight: 600;
          color: #5c5c5c;
          display: block;
          padding-bottom: 45px;
        }

        p {
          font-size: 21px;
          font-weight: 400;
          color: #5c5c5c;
          line-height: 42px;
        }

        .page1_name {
          padding-top: 129px;
          font-size: 21px;
          font-weight: 500;
          color: #5c5c5c;
          line-height: 42px;
        }
      }
    }
    .page_list_right {
      width: 56vw;
      position: relative;
      &::after{
        content:'';
        height: 100%;
        width: 0;
        display: inline-block;
        vertical-align: middle;
      }
      img {
        &:nth-child(1) {
          width: 918px;
          height: 716px;
          display:inline-block block;
          margin: 0 auto;
          vertical-align: middle;
        }

        &:nth-child(2) {
          position: absolute;
          width: 329px;
          height: 394px;
          top: 329px;
          left: 16px;
        }
      }
    }
  }

  .hong_zhua_title {
    position: relative;

    .hong_zhua_title_box {
      width: 159px;
      height: 159px;
      background: #b4dbcf;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }

    > span {
      height: 68px;
      font-size: 48px;
      font-weight: 600;
      color: #5c5c5c;
      top: 28px;
      position: absolute;
      left: 63px;
    }
  }

  .el-table .el-table__cell {
    // padding: 84px 0 36px 0;
  }

  .el-table th.el-table__cell,
  .el-table tr {
    background: #f4f3f1;
  }

  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid rgba(92, 92, 92, 0.2);
  }

  .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    background: rgba(92, 92, 92, 0.2);
  }

  .el-table {
    font-weight: 400;
    color: #5c5c5c;
    .cell{
      font-size: 18PX;
      line-height: 1.5;
    }
  }

  .el-table thead {
    color: #000000;
    font-size: 21PX;
    .cell{
       font-size: 21PX;
      line-height: 1.6;
    }
  }

  .listing {
    // height: 100vh;
    width: 100%;
    background: #f4f3f1;
    padding: 90px 160px;
    box-sizing: border-box;
    position: relative;
    .table {
      width: 1700PX;
    }
    .listing_right_bg {
      position: absolute;
      top: 20px;
      right: 160px;
      height: 160px;
      width: 160px;
      background: #eac06b;
      z-index: 1;
    }

    .listing_left_bg {
      position: absolute;
      bottom: 0;
      left: 0px;
      height: 153px;
      width: 160px;
      background: #b4dbcf;
      z-index: 1;
    }

    .listing_title {
      height: 60px;
      font-size: 42px;
      font-weight: 600;
      color: #5c5c5c;
      line-height: 60px;
      margin-bottom: 50px;
    }
  }

  .interview {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 300px 170px 100px 160px;
    position: relative;
    background: #ffffff;
    border-bottom: 1px solid rgba(92, 92, 92, 0.2);

    .interview_title {
      position: absolute;
      right: 90px;
      top: 90px;
      font-size: 42px;
      font-weight: 600;
      color: #5c5c5c;
      z-index: 111;
    }

    .interview_bg1 {
      width: 160px;
      height: 116px;
      position: absolute;
      top: 0;
      left: 0;
      background: #b4dbcf;
    }

    .interview_bg2 {
      width: 160px;
      height: 182px;
      position: absolute;
      top: 0;
      right: 0;
      background: #eac06b;
    }

    .interview_bg3 {
      width: 160px;
      height: 185px;
      position: absolute;
      top: 116px;
      left: 160px;
      background: #5c5c5c;
    }

    .interview_bg4 {
      width: 160px;
      height: 160px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #eac06b;
    }

    .interview_footer {
      display: flex;
      justify-content: space-between;
      font-size: 21px;
      color: #5c5c5c;
      padding: 40px 0;
      // margin-bottom: 100px;
      .interview_footer_img {
        width: 220px;
        height: 14px;
      }
    }

    .interview_content_info {
      position: relative;

      .interview_content_info_bg {
        position: absolute;
        right: 40px;
        top: 0;
        width: 160px;
        height: 425px;
        background: #b4dbcf;
      }

      .info_box {
        position: relative;
        z-index: 11;
        color: #5c5c5c;
        font-size: 21px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 400;

        .info_box_title {
          padding-top: 174px;
          font-size: 27px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
        }

        .info_box_text {
          padding-top: 50px;
          line-height: 42px;
          margin-bottom: 130px;
        }
      }
    }

    .video_box {
      width: 100%;
      height: 536px;
      position: relative;
      z-index: 1000;

      .video {
        width: 100%;
        height: 100%;
        // background: #F4F3F1;
      }

      .video_p {
        position: absolute;
        bottom: -40px;
        right: 160px;
        font-size: 21px;
        font-weight: 500;
        color: #5c5c5c;
      }

      .video_play {
        position: absolute;
        width: 100%;
        height: 536px;
        top: 0px;
        background: url("./components/image/video02.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        > img {
          width: 78px;
          height: 78px;
          cursor: pointer;

          &:hover {
            position: relative;
            opacity: 0.75;
          }

          &:active {
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }
}
</style>
